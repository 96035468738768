import { createMuiTheme } from '@material-ui/core/styles';
import deepOrange from '@material-ui/core/colors/deepOrange';
import moment from 'moment-timezone'
import pathToRegexp from 'path-to-regexp'


import {
  ACTIVITY_WAITING_FOR_LOCATION,
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS,
  DEMO_KEYPAD_REPLACE,
  FACILITY_NOT_SET,
  LOCATION_NOT_SET,
  STATUS_IN_PROGRESS,
  STATUS_MESSAGE_TYPE_INFO,
  rStudentPasscodeChar,
  rStudentPasscode,
  COLOR_PRIMARY,
  AT_TYPE_ID_TARDY,
  AT_TYPE_ID_ABSENT,
  AT_TYPE_ID_TARDY_EXCUSED,
  AT_TYPE_ID_ABSENT_EXCUSED,
  negativeAttendanceTypes,
  ID_ZERO,
  FORMAT_YMD,

} from './constants'
import {homePage} from "apps/admin/pages/Home"
import {reportsPage} from "apps/admin/pages/Reports"
import {studentAttendancePageDetail} from "apps/admin/pages/StudentAttendance"
import {locationsPage} from "apps/admin/pages/Locations"
import {locationDetailPage} from "apps/admin/pages/LocationDetail"
import {messagingPage} from "apps/admin/pages/Messaging"
import {bellSchedulePage} from "apps/admin/pages/BellSchedules"
import {settingsPage} from "apps/admin/pages/Settings"
//import {facilitiesPage} from "apps/admin/pages/Facilities"
import {userSearchPage} from "apps/admin/pages/Users"
import {studentSearchPage} from "apps/admin/pages/Students"
import {studentLocatorPage} from "apps/admin/pages/StudentLocator"
import {jsPretty} from "redux/utils";
import {buildingsPage} from "apps/admin/pages/Buildings";
import {timezoneNotSet} from "redux/constants";
import {semesterRulesPage} from "apps/admin/pages/SemesterRules";
import {accountSettigsPage} from "apps/admin/pages/AccountMenuPages/AccountSettigs";
const todayYMD = moment().format(FORMAT_YMD)

/**
 * Theme and colors are defined here!
 * @type {Theme}
 */
const theme = createMuiTheme({
  palette: {
    primary:{
      main: COLOR_PRIMARY
    } ,
    secondary: {
      main: deepOrange[700],
      //main: lightGreen[800],
      //main: deepOrange[800],
    }
  },
})

const mainNavPages = [
  homePage,
  studentSearchPage,
  studentLocatorPage,
  reportsPage,
  locationsPage,
  buildingsPage,
  //facilitiesPage,
  userSearchPage,
  messagingPage,
  bellSchedulePage,
  semesterRulesPage,
  settingsPage,
]

const accountMenuPages = [
  accountSettigsPage,
]

const pages = mainNavPages.concat([
    locationDetailPage,
    studentAttendancePageDetail,  
  ],
  accountMenuPages
)

export const staffMainNavePages = [
  homePage,
  studentSearchPage,
  studentLocatorPage,
  reportsPage,
]

export const staffPages = staffMainNavePages.concat([
  locationDetailPage,
  studentAttendancePageDetail
],
  accountMenuPages)


/**
 * This object represents the base, uninitialized state of the application.
 * Use the Chrome Redux DevTools extension to inspect the state in real time. https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd
 */
const baseState = {
  theme,
  admin: {
    auth: true,
    entityChooser: {
      entities:false
    },
    buildingChooser: {
      buildings:false
    },
    newBuildingLocationsSyncWait: {
      buildingsIds: []      
    },
    loggedInUser: false,
    mainNavExpanded: false,
    byId:{
      atType: ()=>false,
      building: ()=>false,
      facility: ()=>false,
      location: ()=>false,
      locationByBuilding: ()=>false,
    },
    currentPageSettings:{ //the primary purpose for this node is to store poll jobs that can automatically be canceled on page change.
      name:'none',
      settings:{},
      timedJobs:[]
    },
    currentPath: '',
    currentPage: homePage,
    currentFacilityId: '',
    currentItemId:'',
    dialogs:{
      kioskConfigurationEditor: false,
      atRecordEditor: false,
      bellScheduleEditor: false,
      messageContentEditor: false,
      userAccountEditor: false,
      userAccountUploader: false,
      workerJob: false,
      workerJobLogs: false,
      login: false,
      attendanceMonitorCoursesEditor: false,
      attendanceMonitorLocationsEditor: false,
      overridableAttendanceTypesEditor: false,
      semesterRuleEditor: false,
    },
    contentRoles:[],
    facilities:[],
    locations:[],
    kioskDefaultConfigurations:[],
    kioskInstances:[],

    homePage: {
      lastPollJSON:'',
      dayByHour:{},
      dayTotals:{
        [AT_TYPE_ID_TARDY]:0,
        [AT_TYPE_ID_ABSENT]:0,
        [AT_TYPE_ID_TARDY_EXCUSED]:0,
        [AT_TYPE_ID_ABSENT_EXCUSED]:0
      },
      weekdays:{ //here to aid in graph library rendering on home page (empty data object on 1st render causes it to have issues.)
        0:{
          [AT_TYPE_ID_TARDY]:0,
          [AT_TYPE_ID_ABSENT]:0,
          [AT_TYPE_ID_TARDY_EXCUSED]:0,
          [AT_TYPE_ID_ABSENT_EXCUSED]:0
        },
        1:{
          [AT_TYPE_ID_TARDY]:0,
          [AT_TYPE_ID_ABSENT]:0,
          [AT_TYPE_ID_TARDY_EXCUSED]:0,
          [AT_TYPE_ID_ABSENT_EXCUSED]:0
        },
        2:{
          [AT_TYPE_ID_TARDY]:0,
          [AT_TYPE_ID_ABSENT]:0,
          [AT_TYPE_ID_TARDY_EXCUSED]:0,
          [AT_TYPE_ID_ABSENT_EXCUSED]:0
        },
        3:{
          [AT_TYPE_ID_TARDY]:0,
          [AT_TYPE_ID_ABSENT]:0,
          [AT_TYPE_ID_TARDY_EXCUSED]:0,
          [AT_TYPE_ID_ABSENT_EXCUSED]:0
        },
        4:{
          [AT_TYPE_ID_TARDY]:0,
          [AT_TYPE_ID_ABSENT]:0,
          [AT_TYPE_ID_TARDY_EXCUSED]:0,
          [AT_TYPE_ID_ABSENT_EXCUSED]:0
        },
        5:{
          [AT_TYPE_ID_TARDY]:0,
          [AT_TYPE_ID_ABSENT]:0,
          [AT_TYPE_ID_TARDY_EXCUSED]:0,
          [AT_TYPE_ID_ABSENT_EXCUSED]:0
        },
        6:{
          [AT_TYPE_ID_TARDY]:0,
          [AT_TYPE_ID_ABSENT]:0,
          [AT_TYPE_ID_TARDY_EXCUSED]:0,
          [AT_TYPE_ID_ABSENT_EXCUSED]:0
        },
      }
    },
    locationPage:{ //putting this here instead of within the page because not sure how React will handle updates to properties of objects within a list
      search:{
        params:{locationName:'', buildingId:-1},
        matchingLocations:[]
      }
    },
    locationDetailPage: {
      location:null,
      kiosks:[]
    },
    pretendSkywardConnected:false,
    reportPage: {
      jsbCustomConfiguration: false,
      firstDateYMD: todayYMD,
      lastDateYMD: todayYMD,
      reportTypes:[],
      reportTypeId:ID_ZERO,
    },
    studentSearchPage: {
      searchBy:'name', //vendorId, id?
      searchString:'',
      noStudentsMessage:'', //set in reducer
      matchingStudents:[]
    },
    locatorStudentPicker: {
      searchString: '',
      matchingStudents: [],
      statusMessage: null,
      selectedStudent: {}
    },
    locatorStudentInfo: {
      currentLocation: null,
      scheduledLocation: null,
      grade: null,
      gradYear: null,
      defaultEntityName: null,
      lastCheckLocation: null,
      lastCheckTime: null,
      queueInfo: {
        retrieved: false,
        currentQueueLocation: '',
        queues: [
          // {
          //   queueType: null,
          //   inTime: null,
          //   outTime: null,
          //   status: null,
          // }
        ]
      },
    },
    queueSyncResponse: {
      synced: false,
      syncSuccess: null,
      isSyncing: false,
    },
    locatorStudentInfoDefault: {
      currentLocation: null,
      scheduledLocation: null,
      grade: null,
      gradYear: null,
      defaultEntityName: null,
      lastCheckLocation: null,
      lastCheckTime: null,
      queueInfo: {
        retrieved: false,
        currentQueueLocation: '',
        queues: []
      },
    },
    locatorSchedule: {
      pickerDate: null,
      periods: []
    },    
    studentDetailPage: {
      student:false,
      weekView:{},
      listView:{
        pageNum:0,
        totalCount:0,
        pageSize:50
      },
      attendanceReport:{
        report:false,
        jsbCustomConfiguration: false,
        firstDateYMD: todayYMD,
        lastDateYMD: todayYMD,
        reportTypeId:ID_ZERO,
      },
      schedDispSettings: {
        view: {
          student: {
            calendar: {
              event: {
                nameFields: null,
                toolTipFields: null,
              }
            }
          }
        }
      }
    },
    userAccountSearchPage: {
      search:{
        searchString:'',
        role:'',
      },
      searchResults:[]
    },
    mainNavPages,
    accountMenuPages,
    pages,
    settings: {
      name:'',
      serverHostUrl:'',
      logoUrl:'',
      jsbTimezone:timezoneNotSet,
      staLicense:'',

      oAuthSignupUrl:'',
      oAuthTokenUrl:'',
      oAuthClientId:'',
      oAuthSecret:'',

      skywardApiUrl:'',
      skywardClientId:'',
      skywardClientSecret:'',

    },
    workerJobs:[],
    snackMessage:{
      show:false,
      messageType:STATUS_MESSAGE_TYPE_INFO,
      message:'',
      autoHideMillis: DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS
    },
    courses:[],
    attendanceMonitorCoursesChooser: {
      courses:[]
    },
    allLocations:[],
    attendanceMonitorLocationsChooser: {
      locations:[]
    },
    overridableAttendanceTypesChooser:{
      atTypes:[]
    },
    schoolYears:{
      schooltrakCurrent: {},
      skywardCurrent: {}
    },
    schoolYearMigration:{
      status:'',
      message: '',
    },
    kioskFacilitiesCustomSetupChooser: {
      facilitiesOverrides:[]
    },
    pirate:{
      addKiosksToAllLocations:false,
      generatingNegativeAttendance:false,
      deleteNegativeAttendanceFromDB:false,
      negativeAttendance: negativeAttendanceTypes.reduce((acc, item) => {
        acc[item.name] = {
          atUserIdCsv:'',
          curve:'linear',
          useAtUserIdCsv:true,
          start:0,
          max:0,
          end:0
        }
        return acc
      }, {
        ymdEnd:'',
        ymdStart:'',
      })
    },
    skywardInfo: {
      // skywardId: null,
      // skywardFullName: null,
    },
  },

  kiosk:{
    id:'New',
    printSlip:{
      html:'',
      style:{}
    },
    loggedInUser:false,
    templates: {
      atRecordSlips: {
        noTemplate: (data)=> '<pre>Error: We have no template for this data\n' + jsPretty(data) + '</pre>',
        [AT_TYPE_ID_TARDY]: (data)=>'<pre>Error: Tardy template not initialized\n' + jsPretty(data) + '</pre>',
        [AT_TYPE_ID_ABSENT]: (data)=>'<pre>Error: Absence template not initialized\n' + jsPretty(data) + '</pre>'
      },
      teplatesUpdateTime: false,
    },
    isLocked: true,
    menuDrawerOpen: false,
    currentActivity: ACTIVITY_WAITING_FOR_LOCATION,
    previousActivity: [],
    demoMode: DEMO_KEYPAD_REPLACE,
    idCode:'',
    uIdCodeLastModified: Date.now(),
    configuration:{
      rIdCodeChar: rStudentPasscodeChar,
      rIdCode: rStudentPasscode
    },
    dialogs:{
      login: false,
      staticContentDialog: false
    },
    isReady:false,
    location: LOCATION_NOT_SET,
    facilities:[],
    locations:[],
    locationChooser: {
      facility: FACILITY_NOT_SET,
      location: LOCATION_NOT_SET,
      locations:[LOCATION_NOT_SET],
      building: FACILITY_NOT_SET
    },
    atTypeChooser:{
      atType:false,
      atTypes:[]
    },
    atType:false,
    snackMessage:{
      show:false,
      messageType:STATUS_MESSAGE_TYPE_INFO,
      message:'',
      autoHideMillis: DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS
    },
    sendingAttendanceDialog:{
      status:STATUS_IN_PROGRESS,
      message:''
    },
    capabilities:{
      canPrint:{
        val:true,
        changeable:false
      },
      enableGps:{
        val:false,
        changeable:false
      },
      enableCamera:{
        val:false,
        changeable:false
      },
      acceptKeyboardInput:{
        val:true,
        changeable:false
      },
      requestSendReport:{
        val:true,
        changeable:false
      },
      enableKeyPad:{
        val:true,
        changeable:false
      },
      atType:{
        val:0,
        changeable:false
      },
      attendanceDirection:{
        val:'in',
        changeable:false
      },
      enableEmailSlip:{
        val:false,
        changeable:false
      },
    },
    showVideo:false,
    cameraTorchOn: false,
    //different UI components are enabled/disabled based on application state. This object represents their enablement
    enabled:{
      keypad:true,
      keyboardCodeInput:true,
      report:false,
      checkIn:false,
      checkOut:false,
    }
  },

  install:{
    dialogs:{
      login:false,
    },
    isReady:false,
    isDirty:false,
    step3: false,
    lastStringify:'',
    browserCurrentStep:0
  },

  common: {
    snackMessage:{
      show:false,
      messageType:STATUS_MESSAGE_TYPE_INFO,
      message:'',
      autoHideMillis: DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS,
    },
    maintenance: {
      isEnabled: false,
      pageContent: false
    }
  }
}

baseState.admin.pages.forEach(page => {
  if(page.routes) {
    page.reTos = page.routes.map(route => pathToRegexp(route))
  } else {
    page.reTos = [pathToRegexp(page.to)]
  }
  //page.reTo = page.route? pathToRegexp(page.route) : pathToRegexp(page.to)
})

export default baseState